<template>
  <b-card
    v-if="devicetypeData"
  >
    <b-card-title>Mise à jour</b-card-title>
    <b-form
      @submit.prevent
      @submit="updateDevicetype(devicetypeData)"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Nom"
            label-for="h-name"
            label-cols-md="4"
          >
            <b-form-input
              id="h-name"
              v-model="devicetypeData.name"
              type="text"
              placeholder="Nom"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Commentaire"
            label-for="h-comment"
            label-cols-md="4"
          >
            <b-form-input
              id="h-comment"
              v-model="devicetypeData.comment"
              placeholder="Commentaire"
              type="text"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Sauvegarder
          </b-button>
          <b-link
            :to="{ name: 'device-types' }"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-primary"
            >
              Annuler
            </b-button>
          </b-link>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton,
  BCard, BCardTitle, BLink,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
/* eslint no-param-reassign: 0 */

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BCardTitle,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      devicetypeData: {},
    }
  },
  mounted() {
    store.dispatch('devicetype/findDevicetype', this.$router.currentRoute.params.id)
      .then(response => {
        this.devicetypeData = response
      })
  },
  methods: {
    async updateDevicetype(devicetypeData) {
      const updateDevicetype = devicetypeData
      await this.$store.dispatch('devicetype/updateDevicetype', { devicetypeId: this.$router.currentRoute.params.id, body: updateDevicetype })
        .then(() => {
          this.$router.push({ name: 'device-types' })
        })
    },
  },
}
</script>
